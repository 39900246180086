exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-components-about-js": () => import("./../../../src/pages/about-us/components/about.js" /* webpackChunkName: "component---src-pages-about-us-components-about-js" */),
  "component---src-pages-about-us-components-discover-hero-js": () => import("./../../../src/pages/about-us/components/DiscoverHero.js" /* webpackChunkName: "component---src-pages-about-us-components-discover-hero-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-all-tours-components-discover-hero-js": () => import("./../../../src/pages/all-tours/components/DiscoverHero.js" /* webpackChunkName: "component---src-pages-all-tours-components-discover-hero-js" */),
  "component---src-pages-all-tours-components-tours-grid-js": () => import("./../../../src/pages/all-tours/components/ToursGrid.js" /* webpackChunkName: "component---src-pages-all-tours-components-tours-grid-js" */),
  "component---src-pages-all-tours-index-js": () => import("./../../../src/pages/all-tours/index.js" /* webpackChunkName: "component---src-pages-all-tours-index-js" */),
  "component---src-pages-contact-us-components-contact-us-js": () => import("./../../../src/pages/contact-us/components/contactUs.js" /* webpackChunkName: "component---src-pages-contact-us-components-contact-us-js" */),
  "component---src-pages-contact-us-components-discover-hero-js": () => import("./../../../src/pages/contact-us/components/DiscoverHero.js" /* webpackChunkName: "component---src-pages-contact-us-components-discover-hero-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-home-components-about-js": () => import("./../../../src/pages/home/components/about.js" /* webpackChunkName: "component---src-pages-home-components-about-js" */),
  "component---src-pages-home-components-blog-js": () => import("./../../../src/pages/home/components/blog.js" /* webpackChunkName: "component---src-pages-home-components-blog-js" */),
  "component---src-pages-home-components-book-js": () => import("./../../../src/pages/home/components/book.js" /* webpackChunkName: "component---src-pages-home-components-book-js" */),
  "component---src-pages-home-components-form-js": () => import("./../../../src/pages/home/components/form.js" /* webpackChunkName: "component---src-pages-home-components-form-js" */),
  "component---src-pages-home-components-hero-js": () => import("./../../../src/pages/home/components/hero.js" /* webpackChunkName: "component---src-pages-home-components-hero-js" */),
  "component---src-pages-home-components-new-reviews-js": () => import("./../../../src/pages/home/components/new-reviews.js" /* webpackChunkName: "component---src-pages-home-components-new-reviews-js" */),
  "component---src-pages-home-components-offers-js": () => import("./../../../src/pages/home/components/offers.js" /* webpackChunkName: "component---src-pages-home-components-offers-js" */),
  "component---src-pages-home-components-reviews-js": () => import("./../../../src/pages/home/components/reviews.js" /* webpackChunkName: "component---src-pages-home-components-reviews-js" */),
  "component---src-pages-home-components-sucess-js": () => import("./../../../src/pages/home/components/sucess.js" /* webpackChunkName: "component---src-pages-home-components-sucess-js" */),
  "component---src-pages-home-components-welcome-js": () => import("./../../../src/pages/home/components/welcome.js" /* webpackChunkName: "component---src-pages-home-components-welcome-js" */),
  "component---src-pages-home-components-why-js": () => import("./../../../src/pages/home/components/why.js" /* webpackChunkName: "component---src-pages-home-components-why-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shuttle-me-components-discover-hero-js": () => import("./../../../src/pages/shuttle-me/components/DiscoverHero.js" /* webpackChunkName: "component---src-pages-shuttle-me-components-discover-hero-js" */),
  "component---src-pages-shuttle-me-components-shuttle-js": () => import("./../../../src/pages/shuttle-me/components/shuttle.js" /* webpackChunkName: "component---src-pages-shuttle-me-components-shuttle-js" */),
  "component---src-pages-shuttle-me-index-js": () => import("./../../../src/pages/shuttle-me/index.js" /* webpackChunkName: "component---src-pages-shuttle-me-index-js" */),
  "component---src-pages-tours-components-featured-tours-js": () => import("./../../../src/pages/tours/components/featured-tours.js" /* webpackChunkName: "component---src-pages-tours-components-featured-tours-js" */),
  "component---src-templates-blogdetails-js": () => import("./../../../src/templates/blogdetails.js" /* webpackChunkName: "component---src-templates-blogdetails-js" */),
  "component---src-templates-tourdetails-js": () => import("./../../../src/templates/tourdetails.js" /* webpackChunkName: "component---src-templates-tourdetails-js" */)
}

